import {ApplicationSuite} from '@verily-src/phaf-runtime-helpers';
import './publicPath';
// Need to hoist public path setting to top so ES6 imports do not come before it.
import userAnalytics, {getApiKey} from '@verily-src/analytics';
import {Auth} from '@verily-src/auth';
import {NavProps} from '@verily-src/nav';
// eslint-disable-next-line node/no-extraneous-import
import '@verily-src/bundle-utility';
import '@verily-src/me-web-home/routes';
import '@verily-src/me-web-userprofile/routes';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import '@verily-src/phaf-unified-api';
import {Application, registerApplication, start} from 'single-spa';
import {
  constructApplications,
  constructLayoutEngine,
  constructRoutes,
} from 'single-spa-layout';
import {HTMLLayoutData} from 'single-spa-layout/dist/types/isomorphic/constructRoutes';
import Favicon from './assets/favicon.svg';
import './microfrontend-layout.css';
import microfrontendLayout from './microfrontend-layout.html';
import {noAuthScreenRoutes} from './noAuthScreenRoutes';

// The callback URL to redirect to after authentication.
// Must be specified as a valid allowed callback URL in the Auth0 Application Settings.
// See https://auth0.com/docs/get-started/authentication-and-authorization-flow/add-login-auth-code-flow#parameters
const redirectUri = `${window.location.origin}/me/auth`;

// TODO: Delete feature flag after merged to production
const loadingScreenEnabled = config.getBoolean(
  'IMPROVEMENT_LOADING_SCREEN_ENABLED'
);
let hidingLoadingScreenTries = 0;
function hideLoadingScreen() {
  try {
    const loadingScreen = document.getElementById('unauthed-loading-screen');
    if (loadingScreen) {
      loadingScreen.style.display = 'none';
    } else {
      if (hidingLoadingScreenTries < 3) {
        console.warn(
          'Element with ID "unauthed-loading-screen" not found. Retrying...'
        );
        hidingLoadingScreenTries++;
        setTimeout(hideLoadingScreen, 1000);
      } else {
        console.warn(
          'Element with ID "unauthed-loading-screen" not found. No more retries.'
        );
      }
    }
  } catch (error) {
    console.error('An error occurred while hiding the loading screen:', error);
  }
}

// Never show auth loading screen if no feature flag or for certain routes (such as PSAT)
const currentPath = window.location.pathname;
if (
  !loadingScreenEnabled ||
  noAuthScreenRoutes.some(route => currentPath.includes(route))
)
  hideLoadingScreen();

Auth.setUp({
  domain: config.getString('AUTH_DOMAIN'),
  clientId: config.getString('AUTH_CLIENT_ID'),
  audience: config.getString('AUTH_AUDIENCE'),
  redirectUri: redirectUri,
  usingReactAuthMFE: true,
  enableAutoLogout: true,
  appName: 'me-web',
}).then(() => {
  // Remove the loading screen once confirmed not going to be redirected to Auth0
  Auth.isLoggedIn().then(isLoggedIn => {
    if (isLoggedIn) hideLoadingScreen();
  });
});

// Map of MFE name to whether it should be imported
// If the MFE is not included in this map, we import it by default
const conditionallyImportedMfes: Record<string, boolean> = {
  '@verily-src/enrollment-initialization-mfe': config.getBoolean(
    'FEATURE_PRE_QUAL_SURVEY_ENABLED'
  ),
  '@verily-src/site-selection-mfe': config.getBoolean(
    'FEATURE_SITE_SELECTION_ENABLED'
  ),
  '@verily-src/assistant-mfe': config.getBoolean('FEATURE_ASSISTANT_ENABLED'),
  '@verily-src/enrollment-mfe': config.getBoolean('FEATURE_ENROLLMENT_ENABLED'),
};

// This behavior allows analytics on path without /me/
// https://verily.atlassian.net/browse/PHP-19831
const apiKeyForVerilyMe = getApiKey(ApplicationSuite.VERILY_ME);
userAnalytics(false, {apiKey: apiKeyForVerilyMe});

function setUpFavicon() {
  const faviconElem = document.createElement('link');
  faviconElem.setAttribute('rel', 'icon');
  faviconElem.setAttribute('href', Favicon);
  document.querySelector('head').appendChild(faviconElem);
}
setUpFavicon();

const navProps: NavProps = {
  logo: 'VerilyLogoMeHorizontalIcon',
  logoProps: {viewBox: '0 0 108 24', sx: {width: 'auto'}},
  showDividers: true,
  themeName: 'ConsumerLightTheme',
  locale: navigator.language,
  isResponsive: true,
  hideOn: [
    {
      path: '/me/bundle',
      isPrefix: true,
    },
    {
      path: '/me/mobile',
      isPrefix: true,
    },
    {
      path: '/initialization',
      isPrefix: true,
    },
    {
      path: '/me/site-selection',
      isPrefix: true,
    },
    {
      path: '/me/thank-you',
      isPrefix: true,
    },
    {
      path: '/email',
      isPrefix: true,
    },
    {
      path: '/me/consent',
      isPrefix: true,
    },
    {
      path: '/enrollment',
      isPrefix: true,
    },
  ],
};

const data: HTMLLayoutData = {
  props: {
    nav: {
      ...navProps,
    },
    routes: {},
  },
  loaders: {},
};

// Construct routes & applications, passing in props data.
// Route activation is set up using microfrontend layout route options.
const routes = constructRoutes(microfrontendLayout, data);
const applications = constructApplications({
  routes,
  loadApp({name}) {
    // Conditionally import some MFEs
    if (conditionallyImportedMfes[name] === false) {
      // https://single-spa.js.org/docs/layout-api/#constructapplications
      // states that the loadApp function must return a loading function.
      // If the launch flag is false for a MFE, we return an empty Application.
      return Promise.resolve({} as Application<{}>);
    }
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({routes, applications});

applications.forEach(registerApplication);
layoutEngine.activate();
start();
