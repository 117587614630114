/**
 * List of routes that do not require visual loading screen before CIAM catches.
 * Currently duplicate of hideOn string[] passed into NavProps, but could diverge in the future.
 *
 * Learn more: https://docs.google.com/document/d/1IC1twKHgOMyyROYjtzkwZdtbFUHmbn7AzxulEIf_-3s
 */
export const noAuthScreenRoutes = [
  '/me/bundle',
  '/me/mobile',
  '/initialization',
  '/me/site-selection',
  '/me/thank-you',
  '/email',
  '/me/consent',
  '/enrollment',
];
